const textiloShopButton = () => {
  return (
    <div className="container mb-10 mx-0 min-w-full flex flex-col items-center">
      <button
        className="middle none center rounded-lg bg-textiloBtn py-4 px-20 font-sans text-lg font-bold uppercase text-white shadow-md shadow-gray-500/20 transition-all hover:shadow-lg hover:gray-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        data-ripple-light="true"
      >
        <a
          className="text-gray-50 font-semibold"
          href="https://www.textilo-shop.com"
          target="_blank"
          rel="noreferrer"
        >
          Textilo-Shop
        </a>
      </button>
    </div>
  );
};

export default textiloShopButton;
