const Footer = () => {
  return (
    <footer className="bg-textiloDesign  gray-900 text-white p-4 mt-auto">
      <div className="container mx-auto text-center">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Textilo-Shop. All Rights Reserved.
        </p>
        <p className="text-sm mt-2">by Hanno Liehmann - GrubaroWeb</p>
      </div>
    </footer>
  );
};

export default Footer;
