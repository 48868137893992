/*rechteckigerTeppich.jsx*/

import React, { useState } from "react";
import ErweitererRechteckigerTeppich from "../components/erweitert/erweitertRechteckigerTeppich";
import RechteckigerTeppichFoto from "../components/Fotos/RechteckigerTeppichFoto";

function RechteckigerTeppich() {
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [area, setArea] = useState(0);

  const handleLengthChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9,.]*$/;

    if (regex.test(inputValue)) {
      setLength(inputValue);
      calculateArea(inputValue, width);
    }
  };

  const handleWidthChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9,.]*$/;

    if (regex.test(inputValue)) {
      setWidth(inputValue);
      calculateArea(length, inputValue);
    }
  };

  const calculateArea = (length, width) => {
    const formattedLength = length.replace(",", ".");
    const formattedWidth = width.replace(",", ".");
    const lengthInCm = parseFloat(formattedLength);
    const widthInCm = parseFloat(formattedWidth);
    const lengthInM = lengthInCm / 100;
    const widthInM = widthInCm / 100;
    const calculatedArea = lengthInM * widthInM;
    setArea(calculatedArea);
  };

  // Materialverbräuche pro m² für verschiedene Materialien in Kilogramm
  const materialVerbraeucheKg = [
    {
      name: "Kordel",
      name_kurz: "Kordel",
      verbrauch: 3.5,
      link: "https://www.textilo-shop.com/kordel-kordelgarn",
    },
    {
      name: "Strickschnur",
      name_kurz: "Strickschnur",
      verbrauch: 3.5,
      link: "https://www.textilo-shop.com/strickschnur-",
    },
    {
      name: "Textilo-Textilgarn",
      name_kurz: "Textilo",
      verbrauch: 3.7,
      link: "link_zu_textilo_textilgarn_material",
    },
    {
      name: "Wollschnur",
      name_kurz: "Wollschnur",
      verbrauch: 2.5,
      link: "https://www.textilo-shop.com/wollschnur",
    },

    {
      name: "Filzseil",
      name_kurz: "Filzseil",
      verbrauch: 5.26,
      link: "https://www.textilo-shop.com/filzseil",
    },
  ];

  return (
    <div>
      <div className="mb-12">
        <div>
          <div className="flex flex-wrap mx-3 mb-1">
            <div className="w-full max-w-full px-3 my-2 mx-auto">
              <RechteckigerTeppichFoto />
            </div>
          </div>
          {/* Eingabe Länge */}
          <div className="flex flex-wrap items-center">
            <label
              className="block text-textiloFont text-1xl text-left  pl-0 pr-1"
              htmlFor="lengthInput"
            >
              Länge (cm):
            </label>
            <input
              id="lengthInput"
              type="text"
              value={length}
              onChange={handleLengthChange}
              maxLength={4} // Begrenzt die Eingabe auf maximal 5 Zeichen
              className="my-2 p-0 text-xl border border-gray-300  bg-gray-300 rounded focus:outline-none focus:border-textiloSeaDark w-16"
            />
            {/* Eingabe Breite  */}
            <label
              className="block text-textiloFont ml-1 text-1xl text-left pr-1"
              htmlFor="widthInput"
            >
              Breite (cm):
            </label>
            <input
              id="widthInput"
              type="text"
              value={width}
              onChange={handleWidthChange}
              maxLength={4} // Begrenzt die Eingabe auf maximal 5 Zeichen
              className="my-2 p-0 text-xl border border-gray-300  bg-gray-300 rounded focus:outline-none focus:border-textiloSeaDark w-16"
            />
          </div>
          {/* Ausgabe Flächeninhalt */}
          <p className="text-text-textiloFont mb-3 text-2xl text-textiloSeaDark mt-2">
            {area ? area.toFixed(2) + " m²" : "0,00 m²"}
          </p>
          <hr className="mb-3 border-b-1 border-textiloDesign"></hr>
          {/* Ausgabe Materialverbrauch  */}
          <div>
            <h2 className="text-textiloFont text-2xl text-left ">
              Materialverbrauch
            </h2>
            <ul
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {materialVerbraeucheKg.map((material) => (
                <li
                  className="text-textiloFont text-lg"
                  key={material.name}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {material.name}:{" "}
                    {(area * material.verbrauch * 1000).toFixed(0)} g
                  </span>
                  {/* Füge hier das Badge mit dem Link ein */}
                  <a
                    href={material.link}
                    className="bg-textiloBtn mb-1 text-gray-100 text-base font-semibold me-2 px-2.5 py-0.5 rounded border border-gray-100 inline-flex items-center justify-center"
                  >
                    {material.name_kurz} im Shop
                  </a>
                </li>
              ))}
            </ul>

            {/* weitere Infos Recheckiger Teppich */}
            <ErweitererRechteckigerTeppich />
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default RechteckigerTeppich;
