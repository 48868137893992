const erweitererRunderTeppich = () => {
  return (
    <div className="container mx-0 text-left">
      <hr className="my-4 border-b-1 border-textiloDesign"></hr>
      <h2 className="text-textiloFont text-xl ">Weitere Infos:</h2>
      <p className="text-base text-textiloFont mt-2">
        Der hier errechnete Materialbedarf ist ein{" "}
        <span className="font-bold">Durchschnittswert</span>, der immer auch von
        der eigenen Häkelfestigkeit, der verwendeten Nadelstärke und von der
        jeweiligen Häkel- oder Stricktechnik / Muster abhängig ist. Projekte mit
        dichter Maschenoptik benötigen meist geringfügig mehr Material als
        einfache oder halbe feste Maschen.
      </p>
      <p className="text-base text-textiloFont mt-2">
        {" "}
        <span className="font-bold">Besonderheit Textilgarn</span>: Bei
        Textilgarn können aufgrund der nicht immer identischen Materialstärke
        etwas deutlichere Unterschiede im Materialverbrauch auftreten:{" "}
        <span className="italic text-lg">
          <br></br>
          Dickeres Material verbraucht natürlich mehr als dünneres.
        </span>{" "}
        <p className=" mt-2 mb-2">
          Hier empfiehlt es sich immer, eine kleine „Sicherheitsreserve“
          einzuplanen.
        </p>
      </p>
      <ul className="ml-4 text-base text-textiloFont mt-2 list-disc mb-11">
        <li>
          {" "}
          Nadelstärke: Kordel L und Strickschnur: Nadelstärke 10 mm (zum
          Stricken und Häkeln).{" "}
        </li>
        <li>
          Textilgarn: meist Stärke 12 mm Häkelnadel. Zum Stricken: 10 mm
          Rundstricknadel.
        </li>
        <li> Wollschnur: 15 mm bis 20 mm Häkelnadel (Stricknadel: 15 mm).</li>
        <li>
          {" "}
          Filzseil: 20 mm – 25 mm Häkel – und Stricknadeln (empfohlen:
          Fingerhäkeln)
        </li>
      </ul>
      <hr className="my-3 border-b-1 border-textiloDesign"></hr>
    </div>
  );
};

export default erweitererRunderTeppich;
