const erweitertRunderKorb = () => {
  return (
    <div className="container mx-0 text-left">
      <hr className="my-4 border-b-1 border-textiloDesign"></hr>
      <h2 className="text-textiloFont text-xl ">Weitere Infos</h2>
      <p className="text-base text-textiloFont mt-2">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus dolores
        atque iste tempora quibusdam perspiciatis? Temporibus ducimus ea porro
        aperiam dolore rem tempore, molestias possimus iste nesciunt
        voluptatibus, omnis hic?
      </p>
      <hr className="my-3 border-b-1 border-textiloDesign"></hr>
    </div>
  );
};

export default erweitertRunderKorb;
