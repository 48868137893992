import React, { useState } from "react";
import ErweitertRunderKorb from "../components/erweitert/erweitertRunderKorb";
import RunderKorbFoto from "../components/Fotos/RunderKorbFoto";

function RunderKorb() {
  const [diameter, setDiameter] = useState("");
  const [height, setHeight] = useState("");
  const [surfaceArea, setSurfaceArea] = useState(0);

  const handleDiameterChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9,.]*$/;

    if (regex.test(inputValue)) {
      setDiameter(inputValue);
      calculateSurfaceArea(inputValue, height);
    }
  };

  const handleHeightChange = (event) => {
    const inputValue = event.target.value;
    const regex = /^[0-9,.]*$/;

    if (regex.test(inputValue)) {
      setHeight(inputValue);
      calculateSurfaceArea(diameter, inputValue);
    }
  };

  const calculateSurfaceArea = (diameter, height) => {
    const formattedDiameter = diameter.replace(",", ".");
    const formattedHeight = height.replace(",", ".");
    const diameterInCm = parseFloat(formattedDiameter);
    const heightInCm = parseFloat(formattedHeight);
    const radiusInCm = diameterInCm / 2;
    const radiusInM = radiusInCm / 100;
    const heightInM = heightInCm / 100;
    const baseArea = Math.PI * Math.pow(radiusInM, 2);
    const lateralArea = 2 * Math.PI * radiusInM * heightInM;
    const calculatedArea = baseArea + lateralArea;
    setSurfaceArea(calculatedArea);
  };

  // Materialverbräuche pro m² für verschiedene Materialien in Kilogramm
  const materialVerbraeucheKg = [
    {
      name: "Kordel",
      verbrauch: 3.5,
      link: "https://www.textilo-shop.com/kordel-kordelgarn",
    },
    {
      name: "Strickschnur",
      verbrauch: 3.5,
      link: "https://www.textilo-shop.com/strickschnur-",
    },
    {
      name: "Textilo-Textilgarn",
      verbrauch: 3.5,
      link: "link_zu_textilo_textilgarn_material",
    },
    {
      name: "Wollschnur",
      verbrauch: 3.12,
      link: "https://www.textilo-shop.com/wollschnur",
    },

    {
      name: "Filzseil",
      verbrauch: 3.02,
      link: "https://www.textilo-shop.com/filzseil",
    },
  ];

  return (
    <div>
      <div className="mb-12">
        <div>
          <div className="flex flex-wrap mx-3 mb-1">
            <div className="w-full max-w-full px-3 my-2  mx-auto">
              <RunderKorbFoto />
              {/* { name: "Bändchengarn XL", verbrauch: 2.75 }  */}
            </div>
          </div>
          {/* Eingabe Durchmesser */}
          <div className="flex flex-wrap items-center">
            <label
              className=" block text-textiloFont text-1xl ml-0 pr-1 text-left"
              htmlFor="diameterInput"
            >
              &Oslash; (cm):
            </label>
            <input
              id="diameterInput"
              type="text"
              value={diameter}
              onChange={handleDiameterChange}
              maxLength={4} // Begrenzt die Eingabe auf maximal 5 Zeichen
              className="my-2 p-0 text-xl border border-gray-300  bg-gray-300 rounded focus:outline-none focus:border-textiloSeaDark w-16"
            />
            {/* Eingabe Höhe */}
            <label
              className=" block text-textiloFont ml-1 text-1xl pr-1 text-left"
              htmlFor="heightInput"
            >
              Höhe (cm):
            </label>
            <input
              id="heightInput"
              type="text"
              value={height}
              onChange={handleHeightChange}
              maxLength={4} // Begrenzt die Eingabe auf maximal 5 Zeichen
              className="my-2 p-0 text-xl border border-gray-300  bg-gray-300 rounded focus:outline-none focus:border-textiloSeaDark w-16"
            />
          </div>
          {/* Ausgabe Flächeninhalt */}
          <p className="text-text-textiloFont mb-3 text-2xl text-textiloSeaDark mt-2">
            {surfaceArea ? surfaceArea.toFixed(2) + " m²" : "0,00 m²"}
          </p>
          <hr className="mb-3 border-b-1 border-textiloDesign"></hr>
          {/* Ausgabe Materialverbrauch  */}
          <div>
            <h2 className="text-textiloFont text-2xl text-left ">
              Materialverbrauch
            </h2>
            <ul
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {materialVerbraeucheKg.map((material) => (
                <li
                  className="text-textiloFont text-lg"
                  key={material.name}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {material.name}:{" "}
                  {(surfaceArea * material.verbrauch * 1000).toFixed(0)} g
                  {/* Füge hier das Badge mit dem Link ein */}
                  <a
                    href={material.link}
                    className="bg-textiloBtn mb-1 text-gray-100 text-base font-semibold me-2 px-2.5 py-0.5 rounded border border-gray-100 inline-flex items-center justify-center"
                  >
                    {material.name} im Shop
                  </a>
                </li>
              ))}
            </ul>
            {/* weitere Infos Runder Korb */}
            <ErweitertRunderKorb />
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default RunderKorb;
