const rechteckigerTeppichFoto = () => {
  return (
    <div className="mx-auto block mb-4 relative shrink-0 rounded-[.95rem]">
      <picture>
        <source
          type="image/webp"
          srcSet="/fotos/Materialrechner-Rechteckiger-Teppich-500x500px.webp"
        />
        <img
          className="mx-auto block shrink-0 rounded-[.95rem] photo"
          src="/fotos/Materialrechner-Rechteckiger-Teppich-500x500px.png" // Fallback-Bild im PNG-Format
          alt="Rechteckiger Teppich aus Strickschnur Petrol"
        />
      </picture>
    </div>
  );
};

export default rechteckigerTeppichFoto;
