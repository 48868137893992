import "./App.css";
import Logo from "./components/layout/Logo";
import Footer from './components/layout/footer'
import Tabs from "./components/layout/Taps"
import TextiloShopButton from "./components/buttons/textiloShopButton"

function App() {
  return (

    <div className="mx-auto px-4 max-w-full sm:max-w-screen-md">
      <div>
        <div className="flex flex-col min-h-screen">
          <Logo />
          <main className="flex-grow">

            <Tabs />
            <TextiloShopButton />
          </main>
          <Footer />
        </div>
      </div>
    </div>

  );
}

export default App;
